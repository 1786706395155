<template>
  <div class="container mx-auto page_container__grid [ px-6 md:px-0 pb-10 pt-6 lg:pb-20 lg:pt-13 ]">
    <section class="grid grid-cols-1 md:gap-x-5 h-full">
      <div class="flex flex-col">
        <!-- <AuthSeperator :text="$t('or')" class="mt-6 px-5 lg:px-0" /> -->

        <!-- <SocialButtons class="mt-6 px-5 lg:px-0" /> -->
        <!-- <div class="flex flex-col">
          <AppButton
            class="mt-8 w-full md:mx-auto justify-center uppercase"
            inverted
            disable-hover
            @click="loginWithEmail = true"
          >
            {{ $t('loginWithEmail') }}
          </AppButton>
        </div> -->

        <div class="flex justify-end mb-3 md:hidden">
          <AppLink to="/" class="float-right [ flex items-center justify-center ] [ w-7 h-7 ]">
            <svg-icon-close class="text-primary-1-100" width="25" height="25" />
          </AppLink>
        </div>

        <section v-if="checkout && itemCount">
          <h1 class="text-lg-1 font-bold capitalize">
            {{ $t('checkoutAsGuest') }}
          </h1>
          <p class="text-primary-1-60 mt-3">{{ $t('checkoutAsGuestText') }}</p>
          <AppButton class="block mt-6 justify-center lg:w-92" as="AppLink" to="/checkout/guest-shipping">
            {{ $t('continueAsGuest') }}
          </AppButton>
        </section>

        <AuthSeperator v-if="checkout && itemCount" :text="$t('or')" class="my-6" />

        <h1 class="text-lg-1 font-bold capitalize">
          {{ $t('loginToYourAccount') }}
        </h1>
        <h2 class="mt-4 md:mx-0 md:w-auto lg:w-5/6 text-sm text-gray-900">
          {{ $t('loginToYourAccountDescription') }}
        </h2>
        <div class="flex flex-col">
          <form class="mt-8" @submit="onSubmit">
            <div class="space-y-12">
              <TextInput
                id="username"
                name="email_phone"
                :label="$t('email_phone')"
                type="text"
                rules="required|email_phone"
                :aria-label="$t('email_phone')"
                full-border
                required
              />

              <TextInput
                id="password"
                name="password"
                :label="$t('password')"
                type="password"
                rules="required|min:8"
                filled
                full-border
                required
              />
            </div>

            <div class="mt-2 flex justify-end">
              <AppLink to="/auth/recover" class="text-sm flex items-center">
                <span class="ml-2">{{ $t('forgot') }}</span>
              </AppLink>
            </div>
            <AppButton
              class="mt-8 w-full lg:w-92 justify-center"
              :disabled="!meta.valid"
              :loading="isSubmitting"
              disable-hover
              inverted
              variant="outline"
            >
              {{ $t('loginWithEmail') }}
            </AppButton>
          </form>
        </div>
      </div>
    </section>

    <div class="flex lg:hidden h-1 w-full my-10 bg-gray-400" />

    <div class="flex flex-col w-full md:ml-auto">
      <div class="[ flex flex-col ] [ px-6 lg:px-8 pt-5 lg:pt-6 pb-7 ] bg-primary-2-07">
        <div class="relative w-full [ flex flex-col items-center justify-center md:justify-start ]">
          <div class="z-0 [ ] [ flex flex-col w-full ]">
            <h1 class="text-1.5xl font-bold capitalize">
              {{ $t('newCustomer') }}
            </h1>

            <h2 class="mt-4 md:mx-0 md:w-auto lg:w-5/6 text-sm text-gray-900">
              {{ $t('createNewAccount') }}
            </h2>
            <AppButton
              class="mt-6 w-full justify-center"
              as="AppLink"
              :to="itemCount && checkout ? `/checkout/shipping` : '/register'"
              variant="outline"
            >
              {{ itemCount && checkout ? $t('createAccountAndCheckout') : $t('createAccount') }}
            </AppButton>
          </div>
        </div>
      </div>

      <div class="flex w-full h-64 mt-2.5">
        <picture class="w-full">
          <source srcset="/login-bg.avif" type="image/avif" />
          <img src="/login-bg.jpg" alt="login-bg" class="w-full h-full object-cover" />
        </picture>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// import { isEmail, isPhone } from '@robustastudio/e-commerce/dist/runtime/utils/text';
import * as yup from 'yup';
import { extendIsPhone } from '~/utils/phone';

definePageMeta({
  layout: 'minimal',
});

const { login } = useLogin();
const { back, redirect } = useAppRouter();
const route = useRoute();
const checkout = !!route.query.checkout;
const { error, success } = useAlerts();
const { itemCount } = useCartAttributes();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginWithEmail = ref(false);

const { t: $t } = useI18n({
  useScope: 'local',
});

const { t: _$t } = useI18n({ useScope: 'global' });

const { isSubmitting, handleSubmit, meta, setErrors } = useForm({
  validationSchema: yup.object({
    // validate that should accept a value that is either a valid phone number or a valid email
    email_phone: yup
      .mixed()
      .required(_$t('validation.required') as string)
      .test('email_phone', _$t('validation.invalidEmailOrPhone') as string, function (value) {
        if (!value) return false;
        return isEmail(value.toString()) || extendIsPhone(value.toString());
      }),
    password: yup.string().required(_$t('validation.required') as string),
  }),
});

const onSubmit = handleSubmit(async ({ email_phone, password }) => {
  try {
    const user = await login({
      password,
      phone: email_phone,
    });
    if (process.client && /checkout\/guest-shipping/.test(localStorage.getItem('LS_ROUTE_KEY') || '')) {
      localStorage.setItem('LS_ROUTE_KEY', 'checkout/shipping');
    }

    back();
    success($t('success') as string, $t('greet', { name: user?.firstname }) as string);
  } catch (err) {
    if (/Invalid username or password./i.test((err as Error).message)) {
      error($t('error') as string, $t('invalidCredentials') as string);

      setErrors({
        email_phone: _$t('validation.invalidPhone') as string,
        password: $t('validation.invalidPassword') as string,
      });
      return;
    }

    if (/account sign-in was incorrect/i.test((err as Error).message)) {
      error($t('error') as string, $t('invalidCredentials') as string);

      setErrors({
        email_phone: _$t('validation.invalidPhone') as string,
        password: $t('validation.invalidPassword') as string,
      });
      return;
    }

    if (/\[GraphQL\] This account isn't verified\. Verify by OTP and try again\./.test((err as Error)?.message)) {
      return;
    }

    error('Sign in Error', 'An error occured while signing in, please try again later or contact support');
  }
});

useEvent('OTP_AUTHENTICATED', () => {
  redirect(checkout ? '/checkout/shipping' : '/');
});

useSeoMeta({
  title: $t('pageTitle') as string,
  description: $t('description') as string,
});
</script>

<style lang="postcss" scoped>
form {
  @apply w-full;
  max-width: 570px;
}

.page_container__grid {
  @screen md {
    @apply grid;
    grid-template-columns: 1fr 472px;
    column-gap: 123px;
  }
}
:deep(.TextInput input) {
  @apply h-10;
}
</style>

<i18n>
{
  "en": {
    "pageTitle": "Log In",
    "description": "Log into your account for a seamless checkout process.",
    "email": "Email Address",
    "password": "Password",
    "cta": "Login",
    "forgot": "Forgot password?",
    "validation": {
      "invalidEmail": "You have entered an invalid email or password",
      "invalidPhone": "You have entered an invalid Phone or password",
      "invalidPassword": "Please check your login credentials"
    },
    "error": "Sign in Error",
    "success": "You are signed in",
    "greet": "Welcome back {name}",
    "proceedToCheckout": "Proceed to checkout",
    "haveAnAccount": "Have An Account?",
    "loginToYourAccount": "Login To Your Account",
    "loginToYourAccountDescription": "Log into your account for a seamless checkout process, and to access your loyalty points",
    "backToCart": "Back To Cart",
    "back": "Back",
    "login": "LOG IN",
    "or": "OR",
    "email_phone": "Enter Your Email/Phone",
    "invalidCredentials": "Invalid Phone Or Password",
    "signUp": "Sign up",
    "newCustomer": "NEW CUSTOMER?",
    "createAccount": "Create a new account ",
    "loginWithEmail": "Login to my account",
    "createAccountCta": "Create account",
    "createAccountAndCheckout": "Create account and checkout",
    "asGuest": "As A guest",
    "noProblem": "No Problem! Proceed to checkout as a guest",
    "createNewAccount": "Create a new account and enjoy a seamless checkout and ordering experience, and to be able to use loyal points",
    "continueAsGuest": "Continue As Guest",
    "checkoutAsGuest": "Checkout As Guest",
    "checkoutAsGuestText": "No problem! Proceed to checkout as a guest."
  },
  "ar": {
    "pageTitle": "تسجيل الدخول",
    "description": "قم بالدخول لحسابك ليمكنك إتمام عملية الشراء بسهولة، تتبع طلباتك واكسب نقاط على كل عملية شراء",
    "email": "البريد الإلكتروني",
    "password": "كلمة السر",
    "cta": "تسجيل الدخول",
    "forgot": "هل نسيت كلمة المرور؟",
    "validation": { 
      "invalidEmail": "لقد ادخلت معلومات خاطئه عن حسابك , راجع بياناتك وحاول مجددا",
      "invalidPassword": "يرجى التأكد من بيانات الدخول"
      },
    "error": "فشل في تسجيل الدخول",
    "success": "تم تسجيل الدخول",
    "greet": "مرحبا بعودتك {name}",
    "proceedToCheckout": "الاستمرار بدون إنشاء حساب ",
    "haveAnAccount": "هل لديك حساب؟",
    "loginToYourAccount": "ادخل الى حسابك",
    "loginToYourAccountDescription": "قم بتسجيل الدخول إلى حسابك للحصول على عملية دفع سلسة، وللوصول إلى نقاط الولاء الخاصة بك",
    "backToCart": "العوده الى السله",
    "back": "العوده",
    "signUp": "قم بالتسجيل",
    "newCustomer": "عميل جديد؟",
    "createAccount": "أنشئ حسابًا جديدًا واستمتع بتجربة شراء سلسة",
    "loginWithEmail": "الدخول إلى حسابي",
    "createAccountCta": "إنشاء حساب",
    "createAccountAndCheckout": "إنشاء حساب والدفع",
    "asGuest": "كضيف",
    "noProblem": "ليس هناك أى مشكلة ! انتقل إلى الخروج كضيف",
    "invalidCredentials": "رقم الهاتف او كلمة المرور غير صحيحة",
    "email_phone": "أدخل بريدك الإلكتروني / رقم الهاتف",
    "login": "تسجيل الدخول",
    "or": "أو",
    "createNewAccount": "أنشئ حسابًا جديدًا واستمتع بتجربة شراء سلسة، وحتى تتمكن من استخدام نقاط الولاء",
    "continueAsGuest": "تابع كضيف",
    "checkoutAsGuest": "عملية الشراء كضيف",
    "checkoutAsGuestText": "لا مشكلة! تابع إلى عملية الشراء كضيف."
  }
}
</i18n>
