<template>
  <div class="Seperator">
    <p>{{ text || $t('useEmail') }}</p>
  </div>
</template>

<script setup lang="ts">
defineComponent({
  name: 'AuthSeperator',
});

const { t: $t } = useI18n({
  useScope: 'local',
});

defineProps({
  text: {
    type: String,
    default: undefined,
  },
});
</script>

<style lang="postcss" scoped>
.Seperator {
  @apply w-full items-center text-system-gray text-sm;
  max-width: 570px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 10px;
  &::before,
  &::after {
    content: '';
    height: 1px;
    @apply w-full bg-primary-1-10;
  }
}
</style>

<i18n>
{
  "en": {
    "useEmail": "Or use your email account"
  },
  "ar": {
    "useEmail": "او استخدم البريد الاليكتروني"
  }
}
</i18n>
